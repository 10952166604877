import PlacementLayout from '@components/Cms/PlacementLayout'
import { cmsApi } from '@features/cms/query'
import { setHomeContext } from '@features/cms/slice'
import { userDetailsSelector } from '@features/user/selector'
import { cartSelector } from '@features/order/selector'

import { sendHomeEvent } from '@foundation/analytics/tealium/lib'
import { getUserToken, useAnalyticsData } from '@foundation/hooks/useAnalyticsData'
import LayoutWithSidebar from '@layouts/LayoutWithSidebar'
import { AppState } from '@redux/store'
import { IPage } from '@typesApp/cms'
import { notNull } from '@utils/common'
import { mergePlacements } from '@utils/placements'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Seo } from './Seo'
import { useFeatureFlag } from '@foundation/featureFlag/useFeatureFlag'

interface IHomeProps extends IPage {
  seoAlternateUrls: Record<string, string>
}

const Home: React.FC<{ homePageInitial: IHomeProps }> = ({ homePageInitial }) => {
  const router = useRouter()
  const { seoAlternateUrls } = homePageInitial
  const dispatch = useDispatch()
  const userDetails = useSelector(userDetailsSelector)
  const cart = useSelector(cartSelector)
  const { flags } = useFeatureFlag()
  const [isViewEventDone, setIsViewEventDone] = useState<boolean>(false)
  const { ...homeAnalyticsData } = useAnalyticsData('home')
  const homePage = useSelector((s: AppState) => s.cms.homeContext)

  const [getPageQuery, { data }] = cmsApi.endpoints.getPage.useLazyQuery()

  const { previewDate, filterRulesLocaleOverride } = router.query as {
    previewDate: string
    filterRulesLocaleOverride: string
  }

  const homePlacementsLoaded = homePage?.contentPlacements.every(elem => elem.loaded)

  const homePageData = getHomePageData(homePlacementsLoaded, homePageInitial, data) ?? homePage
  const contentPlacements = homePageData?.contentPlacements || []
  const sidebarNav = contentPlacements.find(placement => placement.name === 'header_left_side_navigation')
  const pagePlacements = contentPlacements.filter(p => p.name !== 'main_placement_1')
  const topPlacement = () => {
    const topPlacementContent = contentPlacements.filter(p => p.name === 'main_placement_1')
    return <PlacementLayout placements={topPlacementContent} />
  }

  useEffect(() => {
    if (!isViewEventDone && cart) {
      homeAnalyticsData.userToken = getUserToken()
      const orderCartId = cart.orderId
      sendHomeEvent(homeAnalyticsData, orderCartId, userDetails)
      setIsViewEventDone(true)
    }
  }, [homeAnalyticsData, userDetails, isViewEventDone, cart])

  const params = useMemo(() => {
    if (previewDate || filterRulesLocaleOverride) return { previewDate, filterRulesLocaleOverride }
    else {
      return undefined
    }
  }, [previewDate, filterRulesLocaleOverride])

  useEffect(() => {
    const stateHomePlsLoaded = homePage?.contentPlacements.every(elem => elem.loaded)
    if (!stateHomePlsLoaded && homePlacementsLoaded) {
      dispatch(setHomeContext(homePageData))
    }
  }, [homePageData, dispatch, homePage, homePlacementsLoaded])

  useEffect(() => {
    const isHomePageDataFull = homePage?.contentPlacements.every(p => p.loaded)
    if (isHomePageDataFull) return
    const includesParams = homePageInitial?.contentPlacements?.map(el => (!el.loaded ? el.name : null)).filter(notNull)
    if (!includesParams || includesParams.length === 0) return
    getPageQuery({ ...params, includePlacements: includesParams, noEnrich: 'hotZones' })
  }, [homePageInitial, getPageQuery, params, homePage])

  return (
    <>
      {flags.show_empty_div && <div id="ff_show_empty_div" style={{ display: 'none' }}></div>}
      <Seo>
        {seoAlternateUrls &&
          Object.entries(seoAlternateUrls).map(([locale, href]) => (
            <link key={locale} rel="alternate" hrefLang={locale} href={href} />
          ))}
      </Seo>
      <LayoutWithSidebar topPlacements={topPlacement()} sidebarNav={sidebarNav}>
        <PlacementLayout placements={pagePlacements} loading={false} contentVisibilityStart={0} />
      </LayoutWithSidebar>
    </>
  )
}

export default Home

function getHomePageData(homePlacementsLoaded, homePageInitial, data) {
  if (homePlacementsLoaded) {
    return null
  }
  const fullPlacement = mergePlacements(homePageInitial?.contentPlacements, data?.contentPlacements)
  const fullHeaderData = Object.assign({}, homePageInitial)
  if (homePageInitial && fullPlacement) {
    fullHeaderData.contentPlacements = fullPlacement
    return fullHeaderData
  }
  return homePageInitial
}
